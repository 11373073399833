import { render, staticRenderFns } from "./DrukDivider.vue?vue&type=template&id=cb907880&scoped=true"
import script from "./DrukDivider.vue?vue&type=script&lang=js"
export * from "./DrukDivider.vue?vue&type=script&lang=js"
import style0 from "./DrukDivider.vue?vue&type=style&index=0&id=cb907880&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb907880",
  null
  
)

export default component.exports