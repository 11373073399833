import { render, staticRenderFns } from "./SourceDoughnutChart.vue?vue&type=template&id=35a60824&scoped=true"
import script from "./SourceDoughnutChart.vue?vue&type=script&lang=js"
export * from "./SourceDoughnutChart.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35a60824",
  null
  
)

export default component.exports